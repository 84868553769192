import "./style.scss";
import * as THREE from "three";
import * as dat from "dat.gui";
import particleVertexShader from "./shaders/particles/vertex.glsl";
import particleFragmentShader from "./shaders/particles/fragment.glsl";
import noiseVertexShader from "./shaders/noise/vertex.glsl";
import noiseFragmentShader from "./shaders/noise/fragment.glsl";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { io } from "socket.io-client";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { gsap } from "gsap";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

Splitting();

const bgMouse = new THREE.Vector2();
const target = new THREE.Vector2();
const mouse = {
  x: 0,
  y: 0,
  accelX: 0,
  accelY: 0,
  lastX: 0,
  lastY: 0,
  velocityX: 0,
  velocityY: 0,
  smoothX: 0,
  smoothY: 0,
  finalSmoothAccel: null,
};

/**
 * Sockets
 */
const chatContainer = document.querySelector(".chat-container");
const socket = io("https://whispering-chamber-09886.herokuapp.com");

// Enter message
const formMsg = document.querySelector(".form-msg");
const btnMsgInput = document.querySelector(".btn-msg-input");

const messageInputTl = gsap.timeline();
messageInputTl.pause();
messageInputTl.to(".btn-msg-input-mask", 0.5, {
  clipPath: "inset(0% 0% 0% 0%)",
  ease: "power3.in",
});
messageInputTl.to(".btn-msg-input-mask", 0.5, {
  clipPath: "inset(0% 0% 0% 100%)",
  ease: "power3.out",
  delay: 0.075,
});

formMsg.addEventListener("submit", (e) => {
  e.preventDefault();
  socket.emit("message", btnMsgInput.value);

  messageInputTl.play();
  messageInputTl.restart();

  setTimeout(() => {
    btnMsgInput.value = "";
  }, 500);
});

// Enter user
const formUser = document.querySelector(".form-user");
const btnUserInput = document.querySelector(".btn-user-input");

formUser.addEventListener("submit", (e) => {
  e.preventDefault();
  socket.emit("setUsername", btnUserInput.value);

  setTimeout(() => {
    btnUserInput.value = "";
  }, 500);
});

// Bottom scroll update
const updateScroll = () => {
  gsap.to(chatContainer, 0.75, {
    scrollTop: chatContainer.scrollHeight,
    ease: "power3.inout",
  });
};

function addMessage(message, update) {
  const content = document.createElement("div");
  content.classList.add("content");

  if (message.user.id == currentUser.id) {
    content.style.alignSelf = "end";
    content.style.textAlign = "right";
  }

  const userName = document.createElement("div");
  userName.classList.add("user-name");
  userName.textContent = message.user.name;

  const userNameMask = document.createElement("div");
  userNameMask.classList.add("user-mask");

  const userMsg = document.createElement("div");
  userMsg.classList.add("user-message");
  userMsg.textContent = message.value;

  const userMsgMask = document.createElement("div");
  userMsgMask.classList.add("user-mask");

  userNameMask.appendChild(userName);
  userMsgMask.appendChild(userMsg);

  content.appendChild(userNameMask);
  content.appendChild(userMsgMask);

  chatContainer.appendChild(content);

  if (update) {
    updateScroll();

    gsap.to(userName, 0.75, {
      transform: "translateY(0%)",
      ease: "power3.out",
    });
    gsap.to(userMsg, 0.75, {
      transform: "translateY(0%)",
      ease: "power3.out",
      delay: 0.15,
    });
  } else {
    userMsg.style.transform = "translateY(0%)";
    userName.style.transform = "translateY(0%)";
  }
}
socket.on("message", (message) => addMessage(message, true));

const chatBar = document.querySelector(".chat-bar");

const updateUserScroll = () => {
  gsap.to(chatBar, 0.75, {
    scrollTop: chatBar.scrollHeight,
    ease: "power3.inout",
  });
};

const addUser = (user) => {
  const userNameConnected = document.createElement("div");
  userNameConnected.classList.add("user-name-connected");

  const userNameConnectedMask = document.createElement("div");
  userNameConnectedMask.classList.add("user-name-connected-mask");

  userNameConnected.textContent = user.name;
  userNameConnected.id = user.id;

  userNameConnectedMask.appendChild(userNameConnected);
  chatBar.appendChild(userNameConnectedMask);

  updateUserScroll();

  gsap.to(userNameConnected, 0.75, {
    transform: "translateY(0%)",
    ease: "power3.out",
  });
};

// Add user
let currentUser;

socket.emit("getUsers");
socket.on("users", (users) => {
  users.forEach((user) => addUser(user));
});

socket.on("userConnection", (user) => {
  if (socket.id == user.id) currentUser = user;
  addUser(user);
});

socket.on("userDisconnection", (user) => {
  removeUser(user);
});

socket.on("updateUsername", (user) => changeUsername(user));

const changeUsername = (user) => {
  if (user.name != "") {
    let userToUpdate = document.getElementById(user.id);
    if (userToUpdate) {
      userToUpdate.id = user.id;
      userToUpdate.textContent = user.name;
    }
  }
};

const removeUser = (user) => {
  document.getElementById(user.id).parentElement.remove();
};

// Check tablet
let isTablet = false;
if (window.matchMedia("(min-width: 1280px)").matches) {
  isTablet = true;
} else {
  isTablet = false;
}

/**
 * Base
 */
let isLoaded = false;

// Loader
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    gsap.to(".app", 0.25, { opacity: 1 });
    gsap.to(".webgl", 0.25, { opacity: 1 });
    gsap.to(".goToDesktop", 0.25, { opacity: 1 });

    gsap.to(".mainTitle .char", 1.5, {
      opacity: 1,
      stagger: { amount: 0.75, from: "random" },
      ease: "power3.out",
      delay: 0.5,
    });

    gsap.to(".btn-user-mask", 0.5, {
      clipPath: "inset(0% 0% 0% 0%)",
      ease: "power3.in",
      delay: 1,
    });
    gsap.to(".btn-user-mask", 0.5, {
      clipPath: "inset(0% 100% 0% 0%)",
      ease: "power3.out",
      delay: 1.5,
    });
    gsap.to(".btn-user", 0, { opacity: 1, pointerEvents: "all", delay: 1.5 });

    gsap.to(".btn-user-input-mask", 0.5, {
      clipPath: "inset(0% 0% 0% 0%)",
      ease: "power3.in",
      delay: 1,
    });
    gsap.to(".btn-user-input-mask", 0.5, {
      clipPath: "inset(0% 100% 0% 0%)",
      ease: "power3.out",
      delay: 1.5,
    });
    gsap.to(".btn-user-input", 0, {
      opacity: 1,
      pointerEvents: "all",
      delay: 1.5,
    });

    gsap.from(camera.position, { x: 0, y: 0, z: 0.75 });
    gsap.to(camera.position, 1.5, { x: 0, y: 0, z: 1, ease: "power3.out" });
    gsap.to(particlesMaterial.uniforms.uTransition, 1.5, {
      value: 0.4,
      ease: "power3.out",
    });
    gsap.to(particlesMaterial.uniforms.uTransition, 1.5, {
      value: 0,
      ease: "power3.inout",
      delay: 0.75,
    });
    gsap.from(particlesMaterial.uniforms.uIntensity, { value: 0.2 });
    gsap.to(particlesMaterial.uniforms.uIntensity, 3, {
      value: 0,
      ease: "power3.out",
      delay: -0.75,
      onComplete: () => {
        isLoaded = true;
      },
    });
    gsap.to(particlesMaterial.uniforms.uRevealAnimation, 1.5, {
      value: 6.0,
      ease: "power3.out",
      delay: 2.25,
    });
  }
);

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

const debugObject = {
  uColor: new THREE.Color("#210D7D"),
  uColor2: new THREE.Color("#01001A"),
  uColor3: new THREE.Color("#7D0D1C"),
  uColor4: new THREE.Color("#1A0500"),
  uColor5: new THREE.Color("#1A350E"),
  uColor6: new THREE.Color("#021800"),
  uColorNoise: new THREE.Color("#A78FE5"),
  uColorNoise2: new THREE.Color("#E58F93"),
  uColorNoise3: new THREE.Color("#97B58F"),
  planeVertices: 15,
};

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update effect composer
  effectComposer.setSize(sizes.width, sizes.height);
  effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  if (window.matchMedia("(min-width: 1280px)").matches) {
    isTablet = true;
    scene.add(robotGroup);
  } else {
    isTablet = false;
    scene.remove(robotGroup);
  }
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(0, 0, 1);
scene.add(camera);

/**
 * Mesh
 */
// Geometry
let ang_rad = (camera.fov * Math.PI) / 180;
let fov_y = camera.position.z * Math.tan(ang_rad / 2) * 2;
const geometry = new THREE.PlaneGeometry(
  fov_y * camera.aspect,
  fov_y,
  sizes.width / debugObject.planeVertices,
  sizes.height / debugObject.planeVertices
);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: false,
  powerPreference: "high-performance",
});
renderer.gammaFactor = 2.2;
renderer.toneMapping = THREE.ACESFilmicToneMapping;
renderer.toneMappingExposure = 1;
renderer.stencil = false;
renderer.preserveDrawingBuffer = false;
renderer.depth = false;
renderer.premultipliedAlpha = false;
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Material
const particlesMaterial = new THREE.ShaderMaterial({
  vertexShader: particleVertexShader,
  fragmentShader: particleFragmentShader,
  uniforms: {
    uTime: { value: 0 },
    uCircleVert: { value: 0.445 },
    uCircleFrag: { value: 0.02 },
    uMouse: { value: bgMouse },
    uIntensity: { value: 0 },
    uColorCircleIntensity: { value: null },
    uTransition: { value: 0.0 },
    uRevealAnimation: { value: null },
    uFrequency: { value: new THREE.Vector2(10, 10) },
    uRes: { value: new THREE.Vector2(window.innerWidth, window.innerHeight) },
    uSize: { value: 2.75 * renderer.getPixelRatio() },
    uColor: { value: new THREE.Color("#210D7D") },
  },
  defines: {
    PR: window.devicePixelRatio.toFixed(1),
  },
  depthWrite: false,
  blending: THREE.AdditiveBlending,
});

const robotMaterial = new THREE.MeshStandardMaterial({
  metalness: 1,
  roughness: 0.35,
});

// Particles
const particles = new THREE.Points(geometry, particlesMaterial);
scene.add(particles);
particles.scale.set(1.15, 1.15, 1.15);

/**
 * Models
 */
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("/draco/");
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);
let robot;
const robotGroup = new THREE.Group();

gltfLoader.load("/model/robot.gltf", (gltf) => {
  robot = gltf.scene;
  robot.scale.set(0.035, 0.035, 0.035);

  robotGroup.add(robot);

  robotGroup.position.set(0, -3, 0);
  robotGroup.rotation.set(Math.PI / 4, Math.PI / 4, 0);

  userInputTimeline();

  robot.traverse((e) => {
    if (e.isMesh) {
      e.material = robotMaterial;
    }
  });
});

/**
 * Lights
 */
const pointLight1 = new THREE.PointLight(new THREE.Color("#210D7D"), 0.75);
scene.add(pointLight1);

const pointLight2 = new THREE.PointLight(new THREE.Color("#01001A"), 0.75);
scene.add(pointLight2);

/**
 * Post processing
 */
const effectComposer = new EffectComposer(renderer);
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
effectComposer.setSize(sizes.width, sizes.height);

const renderPass = new RenderPass(scene, camera);
effectComposer.addPass(renderPass);

const unrealBloomPass = new UnrealBloomPass({
  strength: 2,
  radius: 0.0,
  threshold: 0.2,
});
effectComposer.addPass(unrealBloomPass);

const noiseShader = {
  uniforms: {
    uTime: { value: 0 },
    tDiffuse: { value: null },
    uColor: { value: new THREE.Color("#A78FE5") },
  },
  vertexShader: noiseVertexShader,
  fragmentShader: noiseFragmentShader,
};

const noisePass = new ShaderPass(noiseShader);
effectComposer.addPass(noisePass);

/**
 * Animations
 */
const btnUser = document.querySelector(".btn-user");
const btnDisconnected = document.querySelector(".btn-disconnected");
const colorBtn1 = document.querySelector(".btn-color .color-1");
const colorBtn2 = document.querySelector(".btn-color .color-2");
const colorBtn3 = document.querySelector(".btn-color .color-3");
let colorIs = "blue";

const userInputTl = gsap.timeline();
userInputTl.pause();

btnDisconnected.addEventListener("click", () => {
  userInputTl.reverse();
  console.log("disconnection");
});

btnUser.addEventListener("click", () => {
  if (isLoaded) {
    userInputTl.play();
    console.log("connection");
  }
});

function userInputTimeline() {
  userInputTl.to(".btn-user-input-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.in",
  });
  userInputTl.to(".btn-user-input-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    ease: "power3.out",
  });
  userInputTl.to(".btn-user-input-mask", 0, {
    clipPath: "inset(0% 100% 0% 0%)",
  });

  userInputTl.to(".btn-user-input", 0, {
    opacity: 0,
    pointerEvents: "none",
    delay: -0.5,
  });

  userInputTl.to(".btn-user-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.in",
    delay: -0.5,
  });
  userInputTl.to(".btn-user-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    ease: "power3.out",
  });

  userInputTl.to(".btn-user", 0, {
    opacity: 0,
    pointerEvents: "none",
    delay: -0.5,
  });

  userInputTl.to(".mainTitle .char", 0.5, {
    opacity: 0,
    stagger: { amount: 0.5, from: "random" },
    ease: "power3.out",
    delay: -1,
    onComplete: () => {
      scene.add(robotGroup);
    },
    onReverseComplete: () => {
      scene.remove(robotGroup);
    },
  });

  userInputTl.to(robotGroup.position, 1.5, { y: -0.25, ease: "power3.out" });
  userInputTl.to(robotGroup.rotation, 2, {
    x: 0.0,
    y: 0.0,
    ease: "power3.out",
    delay: -1.5,
  });

  userInputTl.to(".chat-bar-mask .line-right", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -0.75,
  });
  userInputTl.to(".chat-bar-mask .line-left", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -0.75,
  });
  userInputTl.to(".chat-bar-mask .line-top", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.25,
  });
  userInputTl.to(".chat-bar-mask .line-bottom", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.25,
  });
  userInputTl.to(".chat-bar", 1.25, {
    opacity: 1,
    pointerEvents: "all",
    ease: "power3.out",
    delay: -0.45,
  });
  userInputTl.to(".chat-bar-mask", 0.5, {
    opacity: 0,
    ease: "power3.out",
    delay: -1,
  });

  userInputTl.to(".title-bar .char", 0.5, {
    opacity: 1,
    stagger: { amount: 0.5, from: "random" },
    ease: "power3.out",
    delay: -2,
  });

  userInputTl.to(".chat-container-mask .line-right", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.25,
  });
  userInputTl.to(".chat-container-mask .line-left", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.25,
  });
  userInputTl.to(".chat-container-mask .line-top", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.75,
  });
  userInputTl.to(".chat-container-mask .line-bottom", 0.75, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.out",
    delay: -1.75,
  });
  userInputTl.to(".chat-container", 1.25, {
    opacity: 1,
    pointerEvents: "all",
    ease: "power3.out",
    delay: -0.95,
  });
  userInputTl.to(".chat-container-mask", 1.25, {
    opacity: 0,
    ease: "power3.out",
    delay: -1.3,
  });

  userInputTl.to(".title-chat .char", 0.5, {
    opacity: 1,
    stagger: { amount: 0.5, from: "random" },
    ease: "power3.out",
    delay: -2,
  });

  userInputTl.to(".btn-disconnected-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.in",
    delay: -1.5,
  });
  userInputTl.to(".btn-disconnected-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    ease: "power3.out",
    delay: -1,
  });
  userInputTl.to(".btn-disconnected-mask", 0, {
    clipPath: "inset(0% 100% 0% 0%)",
  });

  userInputTl.to(".btn-disconnected", 0, {
    opacity: 1,
    pointerEvents: "all",
    delay: -1,
  });

  userInputTl.to(".btn-color .mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    stagger: 0.075,
    ease: "power3.in",
    delay: -1,
  });
  userInputTl.to(".btn-color .mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    stagger: 0.075,
    ease: "power3.out",
    delay: -0.5,
  });
  userInputTl.to(".btn-color .mask", 0, {
    clipPath: "inset(0% 100% 0% 0%)",
    stagger: 0.075,
  });

  userInputTl.to(".btn-color .color", 0, {
    opacity: 1,
    delay: -0.8,
    stagger: 0.08,
    pointerEvents: "all",
  });

  userInputTl.to(".btn-color .color-1", 0, {
    opacity: 1,
    delay: -0.81,
  });

  userInputTl.to(".btn-msg-input-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.in",
    delay: -1.5,
  });
  userInputTl.to(".btn-msg-input-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    ease: "power3.out",
    delay: -1,
  });
  userInputTl.to(".btn-msg-input-mask", 0, {
    clipPath: "inset(0% 100% 0% 0%)",
  });

  userInputTl.to(".btn-msg-input", 0, {
    opacity: 1,
    pointerEvents: "all",
    delay: -1,
  });

  userInputTl.to(".btn-msg-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 0%)",
    ease: "power3.in",
    delay: -1,
  });
  userInputTl.to(".btn-msg-mask", 0.5, {
    clipPath: "inset(0% 0% 0% 100%)",
    ease: "power3.out",
    delay: -0.5,
  });

  userInputTl.to(".btn-msg-mask", 0, {
    clipPath: "inset(0% 100% 0% 0)",
  });
  userInputTl.to(".btn-msg", 0, {
    opacity: 1,
    pointerEvents: "all",
    delay: -0.5,
  });
}

/**
 * Color switch
 */
function hideInterface() {
  gsap.to(".wrapper", 0.75, { opacity: 0, ease: "power4.out" });
  gsap.to(".btn-color .color", 0.75, { pointerEvents: "none" });
  gsap.to("input", 0.75, { pointerEvents: "none" });
  gsap.to(".wrapper", 0.75, { opacity: 1, ease: "power4.inout", delay: 0.75 });
  gsap.to(".btn-color .color", 0, { pointerEvents: "all", delay: 0.75 });
  gsap.to("input", 0, { pointerEvents: "all", delay: 0.75 });
}

colorBtn1.addEventListener("click", () => {
  if (colorIs != "red") {
    colorIs = "red";
    gsap.to(pointLight1.color, 0.75, {
      r: debugObject.uColor3.r,
      g: debugObject.uColor3.g,
      b: debugObject.uColor3.b,
      ease: "power4.inout",
    });
    gsap.to(pointLight2.color, 0.75, {
      r: debugObject.uColor4.r,
      g: debugObject.uColor4.g,
      b: debugObject.uColor4.b,
      ease: "power4.inout",
    });
    gsap.to(particlesMaterial.uniforms.uColor.value, 0.75, {
      r: debugObject.uColor3.r,
      g: debugObject.uColor3.g,
      b: debugObject.uColor3.b,
      ease: "power4.inout",
    });
    gsap.to(noisePass.uniforms.uColor.value, 0.75, {
      r: debugObject.uColorNoise2.r,
      g: debugObject.uColorNoise2.g,
      b: debugObject.uColorNoise2.b,
      ease: "power4.inout",
    });

    hideInterface();

    colorBtn1.classList.add("isActive");
    colorBtn2.classList.remove("isActive");
    colorBtn3.classList.remove("isActive");

    setTimeout(() => {
      document.documentElement.style.setProperty("--title", "#E58F93");
      document.documentElement.style.setProperty(
        "--backgroundLowOpacity",
        "#1A050050"
      );
      document.documentElement.style.setProperty(
        "--titleLowOpacity",
        "#E58F9350"
      );
      document.documentElement.style.setProperty("--bgblock", "#3F0808");
    }, 750);
  }
});

colorBtn2.addEventListener("click", () => {
  if (colorIs != "green") {
    colorIs = "green";
    gsap.to(pointLight1.color, 0.75, {
      r: debugObject.uColor5.r,
      g: debugObject.uColor5.g,
      b: debugObject.uColor5.b,
      ease: "power4.inout",
    });
    gsap.to(pointLight2.color, 0.75, {
      r: debugObject.uColor6.r,
      g: debugObject.uColor6.g,
      b: debugObject.uColor6.b,
      ease: "power4.inout",
    });
    gsap.to(particlesMaterial.uniforms.uColor.value, 0.75, {
      r: debugObject.uColor5.r,
      g: debugObject.uColor5.g,
      b: debugObject.uColor5.b,
      ease: "power4.inout",
    });
    gsap.to(noisePass.uniforms.uColor.value, 0.75, {
      r: debugObject.uColorNoise3.r,
      g: debugObject.uColorNoise3.g,
      b: debugObject.uColorNoise3.b,
      ease: "power4.inout",
    });

    hideInterface();

    colorBtn1.classList.remove("isActive");
    colorBtn2.classList.add("isActive");
    colorBtn3.classList.remove("isActive");

    setTimeout(() => {
      document.documentElement.style.setProperty("--title", "#97B58F");
      document.documentElement.style.setProperty(
        "--backgroundLowOpacity",
        "#02180050"
      );
      document.documentElement.style.setProperty(
        "--titleLowOpacity",
        "#97E58F50"
      );
      document.documentElement.style.setProperty("--bgblock", "#0B1B08");
    }, 750);
  }
});

colorBtn3.addEventListener("click", () => {
  if (colorIs != "blue") {
    colorIs = "blue";
    gsap.to(pointLight1.color, 0.75, {
      r: debugObject.uColor.r,
      g: debugObject.uColor.g,
      b: debugObject.uColor.b,
      ease: "power4.inout",
    });
    gsap.to(pointLight2.color, 0.75, {
      r: debugObject.uColor2.r,
      g: debugObject.uColor2.g,
      b: debugObject.uColor2.b,
      ease: "power4.inout",
    });
    gsap.to(particlesMaterial.uniforms.uColor.value, 0.75, {
      r: debugObject.uColor.r,
      g: debugObject.uColor.g,
      b: debugObject.uColor.b,
      ease: "power4.inout",
    });
    gsap.to(noisePass.uniforms.uColor.value, 0.75, {
      r: debugObject.uColorNoise.r,
      g: debugObject.uColorNoise.g,
      b: debugObject.uColorNoise.b,
      ease: "power4.inout",
    });

    hideInterface();

    colorBtn1.classList.remove("isActive");
    colorBtn2.classList.remove("isActive");
    colorBtn3.classList.add("isActive");

    setTimeout(() => {
      document.documentElement.style.setProperty("--title", "#A78FE5");
      document.documentElement.style.setProperty(
        "--backgroundLowOpacity",
        "#01001A50"
      );
      document.documentElement.style.setProperty(
        "--titleLowOpacity",
        "#A78FE550"
      );
      document.documentElement.style.setProperty("--bgblock", "#080B3F");
    }, 750);
  }
});

/**
 * Mousemove
 */

// Follows the mouse event
function onMouseMove(event) {
  // Update the mouse variable
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  mouse.accelX = Math.abs(mouse.x - mouse.lastX);
  mouse.accelY = Math.abs(mouse.y - mouse.lastY);

  mouse.lastX = mouse.x;
  mouse.lastY = mouse.y;

  if (isLoaded) {
    gsap.to(bgMouse, 1.5, {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 1 + 0.5,
      ease: "power4.out",
    });
  }

  // Make the sphere follow the mouse
  let vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
  vector.unproject(camera);
  let dir = vector.sub(camera.position).normalize();
  let distance = -camera.position.z / dir.z;
  let pos = camera.position.clone().add(dir.multiplyScalar(distance));

  gsap.to(pointLight1.position, 1.5, {
    x: pos.x,
    y: pos.y,
    z: pos.z + 0.5,
    ease: "power4.out",
  });

  gsap.to(pointLight2.position, 1.5, {
    x: pos.x,
    y: pos.y,
    z: pos.z - 5,
    ease: "power4.out",
  });
}

document.addEventListener("mousemove", onMouseMove, false);

/**
 * Mousedown
 */
function onMouseDown() {
  if (isLoaded) {
    clearTimeout(this.downTimer);
    this.downTimer = setTimeout(function () {
      gsap.to(particlesMaterial.uniforms.uTransition, 0.75, {
        value: 0.1,
        ease: "power4.out",
      });
      gsap.to(".app", 0.75, { opacity: 0, ease: "power4.out" });
      gsap.to("html", 0, { cursor: "none", pointerEvents: "none" });
    }, 150);
  }
}

window.addEventListener("mousedown", onMouseDown, false);

/**
 * Mouseup
 */
function onMouseUp() {
  if (isLoaded) {
    clearTimeout(this.downTimer);
    gsap.to(particlesMaterial.uniforms.uTransition, 0.75, {
      value: 0.0,
      ease: "power4.inout",
    });
    gsap.to(".app", 0.75, { opacity: 1, ease: "power4.inout" });
    gsap.to("html", 0, { cursor: "auto", pointerEvents: "all" });
  }
}

window.addEventListener("mouseup", onMouseUp, false);

/**
 * Animate
 */
const tick = () => {
  if (isTablet) {
    // Smooth target
    target.x = mouse.x * 0.3;
    target.y = -mouse.y * 0.3;

    // Set velocity
    mouse.velocityX += (mouse.accelX - mouse.velocityX) * 0.2;
    mouse.velocityY += (mouse.accelY - mouse.velocityY) * 0.2;

    // Set acceleration
    mouse.accelX *= 0.75;
    mouse.accelY *= 0.75;

    // Set smooth acceleration
    mouse.smoothX += (mouse.velocityX - mouse.smoothX) * 0.03;
    mouse.smoothY += (mouse.velocityY - mouse.smoothY) * 0.03;

    // Fix the value
    mouse.finalSmoothAccel = Math.min(mouse.smoothX + mouse.smoothY, 0.135);

    if (robot) {
      robot.rotation.x += 0.03 * (target.y - robot.rotation.x);
      robot.rotation.y += 0.03 * (target.x - robot.rotation.y);
    }

    camera.rotation.x += 0.03 * (target.y / 6 - camera.rotation.x);
    camera.rotation.y += 0.03 * (target.x / 6 - camera.rotation.y);

    if (isLoaded) {
      particlesMaterial.uniforms.uIntensity.value = mouse.finalSmoothAccel;
    }
  }

  noisePass.uniforms.uTime.value += 0.001;
  particlesMaterial.uniforms.uTime.value += 0.001;

  // Render
  effectComposer.render();

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
